import {useEffect, useRef, useState} from "react";
import {Col, Container, Form, Modal, Row} from "react-bootstrap";
import ReactGA from "react-ga4";

import './App.css';

ReactGA.initialize(process.env.REACT_APP_TRACKING_CODE);
ReactGA.send("pageview");

const timestamp = Date.now();

function App() {
  const [result, setResult] = useState("");
  const ref = useRef();

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    ref.current.focus();
  }, []);

  function onChange(event) {
    setResult(event.target.value);
  }

  function onKeyUp(event) {
    if (event.key === "Enter") {
      if (result.length === 0) {
        // do nothing
      } else if (result.length < 4) {
        setMessage("At least 4 characters.");
        setShow(true);
      } else {
        setMessage("Invalid value.");
        setShow(true);
      }

      sendEvent();
    }
  }

  function sendEvent() {
    ReactGA.event({
      action: "user_request",
      category: "key_enter",
      // label: result ? result : "none",
      value: 1,
      nonInteraction: true
    });
  }

  return (
    <>
      <div className="App">
        <Container>
          <Row className="justify-content-md-center">
            <Col lg={4}>
              <Form.Control
                autoFocus
                ref={ref}
                type="password"
                size="lg"
                className="text-center"
                value={result}
                maxLength={30}
                onChange={onChange}
                onKeyUp={onKeyUp}
              />
            </Col>
          </Row>
        </Container>

        <Modal
          show={show}
          onHide={() => setShow(false)}
          size="sm"
        >
          <Modal.Body>
            <div className="text-center">{message}</div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default App;
